<template>
  <ui-loading-overlay />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import UiLoadingOverlay from '@/components/global/ui/UiLoadingOverlay.vue';
import { definePageMeta, useAuth, useRoute } from '#imports';

onMounted(() => {
  const route = useRoute();
  const { auth } = useAuth();
  auth.loginWithRedirect({
    appState: {
      target: route.query.return ? decodeURIComponent(route.query.return as string) : '/account',
    },
  });
});

definePageMeta({
  key: (route) => route.fullPath,
  pageTitle: 'Login',
  pageDescription: 'Login to your Zeronet account to manage your services and account details.',
});
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
